<template>
    <v-app>
        <v-main class="public-page-bg">

            <v-container fill-height fluid>
                <v-row no-gutters>
                    <v-col sm="8" offset-xl="2" offset-lg="2" offset-md="2">
                        <v-card :loading="loading" class="mx-auto my-12 rounded">
                            <template slot="progress">
                                <v-progress-linear
                                        :color="$globalSettings.progressbarClasses"
                                        height="10"
                                        indeterminate
                                ></v-progress-linear>
                            </template>
                            <v-row no-gutters>
                                <v-col cols="12" sm="7" class="border-right-grey left-section">
                                    <div class="left-section-content">
                                        <img height="80" src="/images/logo.png" class="mr-3">
                                        <img  height="80" src="/images/epb-round.png">
                                        <div class="left-app-texts">
                                            <div class="left-app-name">{{ $globalSettings.app_name_full }}</div>
                                        </div>
                                    </div>

                                </v-col>
                                <v-col cols="12" sm="5" class="right-section px-10">
                                    <div class=""><v-icon small>fas fa-user-lock</v-icon> Authentication</div>
                                    <v-divider class="mt-2"></v-divider>

                                    <validation-observer ref="observer" v-slot="{ invalid }">
                                        <form @submit.prevent="login">
                                            <validation-provider v-slot="{ errors }" name="Username" rules="required">
                                                <v-text-field v-model="formData.Username" type="text" :error-messages="errors" label="Email or BD/NT Number" :disabled="loading" required></v-text-field>
                                            </validation-provider>
                                            <validation-provider v-slot="{ errors }" name="Password" rules="required">
                                                <v-text-field v-model="formData.Password" type="password" :error-messages="errors" label="Account Password" :disabled="loading" required></v-text-field>
                                            </validation-provider>

                                            <v-card-actions class="d-flex flex-row-reverse">
                                                <v-btn block type="submit" :disabled="invalid || loading">Login</v-btn>
                                            </v-card-actions>

                                            <v-btn color="warning" type="button" plain v-on:click="signup('reg')" class="text-underline font-bold"><v-icon small>fas fa-user-plus</v-icon> রেজিস্ট্রেশন সাইনআপ</v-btn><br>
                                            <v-btn color="warning" type="button" plain v-on:click="signup('ren')" class="text-underline font-bold"><v-icon small>fas fa-user-plus</v-icon> রিনিউ সাইনআপ</v-btn><br><br>
                                            <v-btn color="primary" type="button" plain v-on:click="forgot()"><v-icon small>fas fa-question-circle</v-icon> Forgot Password</v-btn><br /><br />
                                            <v-btn color="primary" type="button" plain @click="openExtUrl"><v-icon small class="mr-2">fas fa-book</v-icon> User Manual</v-btn><br />
                                            <!--<v-btn color="primary" type="button" plain @click="openStaticModal('faq')"><v-icon small class="mr-2">fas fa-question-circle</v-icon> FAQ</v-btn>-->
                                            <v-btn color="primary" type="button" plain @click="openStaticModal('help')"><v-icon small class="mr-2">fas fa-headset</v-icon> Help Desk</v-btn>
                                        </form>
                                    </validation-observer>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>

                <v-dialog v-model="formActions.viewStaticModal" width="600">
                    <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                            {{ formActions.staticModalTitle }}
                        </v-card-title>

                        <v-card-text>
                            <v-expansion-panels accordion v-if="formActions.viewStaticModalFaq" class="pt-5">
                                <v-expansion-panel v-for="(item,i) in 5" :key="i">
                                    <v-expansion-panel-header>Question {{i + 1}}?</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>

                            <div v-if="formActions.viewStaticModalhelp" class="pt-5 pb-5">Please call 01881833485 for any kind of inquiry or assistance regarding the usage of this software.</div>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>

                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    import Vue from 'vue';
    import { required } from 'vee-validate/dist/rules';
    import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';

    //setInteractionMode('eager');
    extend('required', {
        ...required,
        message: '{_field_} can not be empty',
    });


    export default {
        name: "Login_view",
        data: () => ({
            loading: false,

            formActions:{
                viewStaticModal: false,
                staticModalTitle: '',
                viewStaticModalFaq: false,
                viewStaticModalhelp: false,
            },
            formData: {
                Username: null,
                Password: null
            }
        }),
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        methods: {
            login: async function () {
                this.loading = true;
                if (await this.$globalHelpers.signIn({Username: this.formData.Username, Password: this.formData.Password})) {
                    if (await this.$globalHelpers.userInit()) {
                        await this.$globalHelpers.gotoDashboard();
                    } else {
                        Vue.$toast.open({message: 'You are not authorized!', type: 'error'});
                    }
                    this.loading = false;
                } else {
                    Vue.$toast.open({message: 'Login failed! Please try again.', type: 'error'});
                    this.loading = false;
                }
            },
            signup: async function (type) {
                await this.$router.push({name: 'Signup', params: {type: type}});
            },
            forgot: async function () {
                await this.$router.push({name: 'Forgot'});
            },
            redirectIfAuthed: async function () {
                if (this.$globalHelpers.getAuthStoreValue('authenticated') && this.$globalHelpers.getAuthStoreValue('token') != null) {
                    await this.$globalHelpers.gotoDashboard();
                }
            },
            openExtUrl: function (){
                window.open('/EMS_User_manual 15_9_24.pdf', '_blank');
            },
            openStaticModal: function (type){
                if(type === 'faq'){
                    this.formActions.staticModalTitle = 'Frequently Asked Questions';
                    this.formActions.viewStaticModal = true;
                    this.formActions.viewStaticModalFaq = true;
                    this.formActions.viewStaticModalhelp = false;
                }
                if(type === 'help'){
                    this.formActions.staticModalTitle = 'Help Desk';
                    this.formActions.viewStaticModal = true;
                    this.formActions.viewStaticModalFaq = false;
                    this.formActions.viewStaticModalhelp = true;
                }
            },
        },
        created() {
            this.redirectIfAuthed();
        }
    }
</script>

<style scoped>
    .left-section{
        position: relative;
        background-image: url('../../assets/images/loginbg.jpg') ;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .left-section-content{
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        margin-bottom: 40px;
    }
    .left-app-texts{
        margin-top: 30px;
    }
    .left-app-name{
        color: white;
        font-size: 24px;
        font-weight: bold;
        font-family: "Source Sans Pro", sans-serif;
        border-radius: 5px;
    }
    .right-section{
        padding-top: 100px !important;
        padding-bottom: 100px !important;
    }
</style>