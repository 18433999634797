import Vue from 'vue'
import VueRouter from 'vue-router'
import Login_view from "../views/auth/Login_view";
import Layout_view from "../views/app/Layout_view";
import Signup_view from "../views/auth/Signup_view";
import Verify_view from "../views/auth/Verify_view";
import Forgot_view from "../views/auth/Forgot_view";
import Certificate_view from "../views/pages/Certificate_view";
import BIDAApplication_view from "@/views/bida/BIDAApplication_view";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login_view
  },
  {
    path: '/signup/:type?',
    name: 'Signup',
    component: Signup_view
  },
  {
    path: '/verify/:email?/:otp?',
    name: 'Verify',
    component: Verify_view
  },
  {
    path: '/forgot/:email?/:otp?',
    name: 'Forgot',
    component: Forgot_view
  },
  {
    path: '/certificate/:id',
    name: 'Certificate',
    component: Certificate_view
  },
  {
    path: '/bida/application/:id/:token/:paymentStatus?',
    name: 'BIDAApplication',
    component: BIDAApplication_view
  },
  {
    path: '/app',
    name: 'App',
    component: Layout_view,
    meta: {isPrivate: true},
    children: [
        {
          path: 'ea/list/:type/:status',
          name: 'App.EA.List',
          meta: {isPrivate: true},
          component: () => import('../views/app/ea/EAs_view')
        },
        {
          path: 'ea/:id/:paymentStatus?',
          name: 'App.EA',
          meta: {isPrivate: true},
          component: () => import('../views/app/ea/EA_view')
        },
        {
          path: 'ea-inspection/:id',
          name: 'App.EA.Inspection',
          meta: {isPrivate: true},
          component: () => import('../views/app/ea/Inspection_view')
        },
        {
          path: 'user/list/:type?',
          name: 'App.User.List',
          meta: {isPrivate: true},
          component: () => import('../views/app/user/Users_view')
        },
        {
          path: 'user/:id/:type?',
          name: 'App.User',
          meta: {isPrivate: true},
          component: () => import('../views/app/user/User_view')
        },
        {
          path: 'factory-type/list/:type?',
          name: 'App.FactoryType.List',
          meta: {isPrivate: true},
          component: () => import('../views/app/factoryType/FactoryTypes_view')
        },
        {
          path: 'factory-type/:id',
          name: 'App.FactoryType',
          meta: {isPrivate: true},
          component: () => import('../views/app/factoryType/FactoryType_view')
        },
        {
          path: 'report/list',
          name: 'App.Report.List',
          meta: {isPrivate: true},
          component: () => import('../views/app/report/Reports_view')
        },
        {
          path: 'svForm/list/:type',
          name: 'App.SvForm.List',
          meta: {isPrivate: true},
          component: () => import('../views/app/svForm/SvForms_view')
        },
        {
          path: 'svForm/:type/:id/:paymentStatus?',
          name: 'App.SvForm',
          meta: {isPrivate: true},
          component: () => import('../views/app/svForm/SvForm_view')
        },
    ]
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  const isPrivate = to.meta.isPrivate;
  let store = Vue.prototype.$store;
  if (isPrivate) {
    if (store.getters["auth/authenticated"] && store.getters["auth/token"] != null) {
      return next();
    } else {
      return next({name: 'Login'});
    }
  } else {
    return next();
  }
});

export default router
