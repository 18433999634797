<template>
    <v-app>
        <v-main class="public-page-bg">

            <v-container fill-height fluid>
                <v-row no-gutters>
                    <v-col sm="4" offset-xl="4" offset-lg="4" offset-md="4">
                        <div class="text-center">
                            <img height="80" src="/images/logo.png" class="mr-3">
                            <img  height="80" src="/images/epb-round.png">
                            <div class="font-bold font-size-20 pt-2">
                                {{ $globalSettings.app_name_full }}
                            </div>
                        </div>
                        <v-card :loading="loading" class="mx-auto my-12 pa-5">
                            <template slot="progress">
                                <v-progress-linear
                                        :color="$globalSettings.progressbarClasses"
                                        height="10"
                                        indeterminate
                                ></v-progress-linear>
                            </template>

                            <div class=""><v-icon small>fas fa-question-circle</v-icon> Forgot Password</div>
                            <v-divider class="mt-2"></v-divider>

                            <validation-observer ref="observer" v-slot="{ invalid }" v-if="step === 1">
                                <form @submit.prevent="sendOTP">
                                    <div>
                                        <validation-provider v-slot="{ errors }" name="Email" rules="required|email">
                                            <v-text-field v-model="formData.Email" type="email" :error-messages="errors" label="Account Email" :disabled="loading" autofocus required></v-text-field>
                                        </validation-provider>
                                    </div>

                                    <v-row no-gutters>
                                        <v-col sm="6">
                                            <v-card-actions>
                                                <v-btn color="primary" type="button" plain v-on:click="login()"><v-icon small>fas fa-angle-left</v-icon> Login</v-btn>
                                            </v-card-actions>
                                        </v-col>
                                        <v-col sm="6">
                                            <v-card-actions class="d-flex flex-row-reverse">
                                                <v-btn type="submit" :disabled="invalid || loading">Continue</v-btn>
                                            </v-card-actions>
                                        </v-col>
                                    </v-row>
                                </form>
                            </validation-observer>
                            <validation-observer ref="observer" v-slot="{ invalid }" v-if="step === 2">
                                <form @submit.prevent="verify">
                                    <div>
                                        <v-alert class="mt-5" dense outlined type="info">OTP expires in {{ timerDisplay }}</v-alert>
                                        <validation-provider v-slot="{ errors }" name="OTP" rules="required">
                                            <v-text-field v-model="formData.OTP" :error-messages="errors" label="OTP" :disabled="loading" required></v-text-field>
                                        </validation-provider>
                                        <validation-provider v-slot="{ errors }" name="Password" rules="required|min:6" vid="Password">
                                            <v-text-field v-model="formData.Password" type="password" :error-messages="errors" label="New Password" :disabled="loading" required></v-text-field>
                                        </validation-provider>
                                        <validation-provider v-slot="{ errors }" name="PasswordConfirmed" rules="required|confirmed:Password">
                                            <v-text-field v-model="formData.PasswordConfirmed" type="password" :error-messages="errors" label="Repeat New Password" :disabled="loading" required></v-text-field>
                                        </validation-provider>
                                    </div>


                                    <v-row no-gutters>
                                        <v-col sm="6"></v-col>
                                        <v-col sm="6">
                                            <v-card-actions class="d-flex flex-row-reverse">
                                                <v-btn type="submit" :disabled="invalid || loading">Continue</v-btn>
                                            </v-card-actions>
                                        </v-col>
                                    </v-row>
                                </form>
                            </validation-observer>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    import Vue from 'vue';
    import { min, confirmed, required, email } from 'vee-validate/dist/rules';
    import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';

    //setInteractionMode('eager');
    extend('required', {
        ...required,
        message: '{_field_} can not be empty',
    });
    extend('email', {
        ...email,
        message: 'This field must be an email'
    });
    extend('confirmed', {
        ...confirmed,
        message: 'The passwords must match'
    });
    extend('min', {
        ...min,
        message: 'This field must be {length} characters'
    });

    export default {
        name: "Forgot_view",
        data: () => ({
            loading: false,

            step: 1,
            timerDisplay: null,

            formData: {
                Email: null,
                OTP: null,
                Password: null,
                PasswordConfirmed: null
            }
        }),
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        methods: {
            sendOTP: async function () {
                /*this.step = 2;
                this.countDown(10);*/
                this.loading = true;
                await this.$axios.get(this.$globalSettings.api.endpoints.authentication.forgot, { params: { email: this.formData.Email} }).then(async (response) => {
                    this.loading = false;
                    Vue.$toast.open({ message: response.data.Msg,  type: response.data.Status});
                    if(response.data.Status === 'success'){
                        this.step = 2;
                        this.countDown(280);
                        //sessionStorage.l = this.formData.Email;
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });
            },
            verify: async function () {
                this.loading = true;
                await this.$axios.post(this.$globalSettings.api.endpoints.authentication.forgotVerify, this.formData).then(async (response) => {
                    this.loading = false;
                    Vue.$toast.open({ message: response.data.Msg,  type: response.data.Status});
                    if(response.data.Status == 'success'){
                        this.loading = false;
                        if (!this.formData.Email) {
                            await this.$router.push({name: 'Login'});
                        }
                        if (await this.$globalHelpers.signIn({Username: this.formData.Email, Password: this.formData.Password})) {
                            if (await this.$globalHelpers.userInit()) {
                                await this.$globalHelpers.gotoDashboard();
                            } else {
                                await this.$router.push({name: 'Login'});
                            }
                            this.loading = false;
                        } else {
                            await this.$router.push({name: 'Login'});
                        }
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });
            },
            login: async function () {
                await this.$router.push({name: 'Login'});
            },
            countDown(duration){
                console.log(duration);
                if (!isNaN(duration)) {
                    let timer = duration, minutes, seconds;
                    let interVal = setInterval(function () {
                        minutes = parseInt(timer / 60, 10);
                        seconds = parseInt(timer % 60, 10);

                        minutes = minutes < 10 ? "0" + minutes : minutes;
                        seconds = seconds < 10 ? "0" + seconds : seconds;

                        this.timerDisplay = minutes + "m : " + seconds + "s";
                        if (--timer < 0) {
                            timer = duration;
                            this.timerEnded();
                            clearInterval(interVal)
                        }
                    }.bind(this), 1000);
                }
            },
            timerEnded(){
                this.timerDisplay = null;
                this.step = 1;
                Vue.$toast.open({ message: 'OTP expired, please try again!',  type: 'error'});
            },
        },
        created() {
            //this.formData.Email = this.$globalHelpers.getAuthStoreValue('verify_email');
            if(this.$route.params.email){
                this.formData.Email = this.$route.params.email;
            }
            if(this.$route.params.otp){
                this.formData.OTP = this.$route.params.otp;
            }
            if(this.$route.params.email && this.$route.params.otp){
                this.step = 2;
            }
        }
    }
</script>

<style scoped>
    .app-name{
        color: #794986;
        font-size: 20px;
    }
    .login-left-bg{

    }
    .login-right-bg{
        background: #512DA8;
    }
</style>