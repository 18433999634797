export default {
    namespaced: true,

    state: {
        authenticated: false,
        token: localStorage.getItem('token'),
        user_id: null,
        user_organization_name: null,
        user_role_id: null,
        user_designation_id: null,
        user_name: null,
        user_email: null,
        user_username: null,
        user_exporter_type: null,
        user_service_office_id: null,
        user_is_department_head: null,
        user_profile_pic: null,
        verify_email: null,
    },

    getters: {
        authenticated (state) {
            return state.authenticated
        },
        token (state) {
            return state.token
        },
        user_id (state) {
            return state.user_id
        },
        user_organization_name (state) {
            return state.user_organization_name
        },
        user_role_id (state) {
            return state.user_role_id
        },
        user_designation_id (state) {
            return state.user_designation_id
        },
        user_name (state) {
            return state.user_name
        },
        user_email (state) {
            return state.user_email
        },
        user_username (state) {
            return state.user_username
        },
        user_exporter_type (state) {
            return state.user_exporter_type
        },
        user_service_office_id (state) {
            return state.user_service_office_id
        },
        user_is_department_head (state) {
            return state.user_is_department_head
        },
        user_profile_pic (state) {
            return state.user_profile_pic
        },
        verify_email (state) {
            return state.verify_email
        },
    },

    mutations: {
        SET_AUTHENTICATED (state, value) {
            state.authenticated = value
        },
        SET_TOKEN (state, value) {
            state.token = value;
            localStorage.setItem('token', value);
        },
        SET_USER_ID (state, value) {
            state.user_id = value
        },
        SET_ORGANIZATION_NAME (state, value) {
            state.user_organization_name = value
        },
        SET_USER_ROLE_ID (state, value) {
            state.user_role_id = value
        },
        SET_USER_DESIGNATION_ID (state, value) {
            state.user_designation_id = value
        },
        SET_USER_NAME (state, value) {
            state.user_name = value
        },
        SET_USER_EMAIL (state, value) {
            state.user_email = value
        },
        SET_USER_USERNAME (state, value) {
            state.user_username = value
        },
        SET_USER_EXPORTER_TYPE (state, value) {
            state.user_exporter_type = value
        },
        SET_SERVICE_OFFICE_ID (state, value) {
            state.user_service_office_id = value
        },
        SET_IS_DEPARTMENT_HEAD (state, value) {
            state.user_is_department_head = value
        },
        SET_USER_PROFILE_PIC (state, value) {
            state.user_profile_pic = value
        },
        SET_VERIFY_EMAIL (state, value) {
            state.verify_email = value
        },
    },

    actions: {
        setToken ({ commit }, {token}) {
            commit('SET_TOKEN', token);
        },
        userInit ({ commit }, {authenticated, user_id, user_organization_name, user_name, user_role_id, user_designation_id, user_email, user_username, user_exporter_type, user_service_office_id, user_is_department_head, user_profile_pic}) {
            commit('SET_AUTHENTICATED', authenticated);
            commit('SET_USER_ID', user_id);
            commit('SET_ORGANIZATION_NAME', user_organization_name);
            commit('SET_USER_NAME', user_name);
            commit('SET_USER_ROLE_ID', user_role_id);
            commit('SET_USER_DESIGNATION_ID', user_designation_id);
            commit('SET_USER_EMAIL', user_email);
            commit('SET_USER_USERNAME', user_username);
            commit('SET_SERVICE_OFFICE_ID', user_service_office_id);
            commit('SET_IS_DEPARTMENT_HEAD', user_is_department_head);
            commit('SET_USER_EXPORTER_TYPE', user_exporter_type);
            commit('SET_USER_PROFILE_PIC', user_profile_pic);
        },
        setVerifyEmail ({ commit }, {email}) {
            commit('SET_VERIFY_EMAIL', email);
        },
    }
}