import Vue from 'vue'
//import Vue.prototype.$store from '../Vue.prototype.$store/index';
import axios from "axios";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

//authentication
async function signIn(credentials) {
    return await axios.post(Vue.prototype.$globalSettings.api.endpoints.authentication.login, credentials)
        .then((response) => {
            if(response.data.Status === 'success'){
                Vue.prototype.$store.dispatch('auth/setToken', {token: response.data.Data.Token});
                return true;
            }
            return false;
        })
        .catch((error) => {
            console.log(error);
            return false;
        })
}

async function userInit() {
    let token = getAuthStoreValue('token');

    return await axios.post(Vue.prototype.$globalSettings.api.endpoints.user.userInit, null, {
        headers: {Authorization: `Basic ${token}`}
    })
        .then((response) => {
            if(response.data.Status === 'success'){
                Vue.prototype.$store.dispatch('auth/userInit', {authenticated: true, user_id: response.data.Data.Id, user_organization_name: response.data.Data.OrganizationName, user_name: response.data.Data.Name, user_role_id: response.data.Data.RoleId, user_designation_id: response.data.Data.DesignationId, user_email: response.data.Data.Email, user_username: response.data.Data.Username, user_exporter_type: response.data.Data.ExporterType, user_service_office_id: response.data.Data.ServiceOfficeId, user_is_department_head: response.data.Data.IsDepartmentHead, user_profile_pic: response.data.Data.ProfilePicPath});
                return true;
            }else{
                Vue.prototype.$store.dispatch('auth/userInit', {authenticated: false, user_id: null, user_organization_name: null, user_name: null, user_role_id: null, user_designation_id: null,  user_email: null, user_exporter_type: null, user_service_office_id: null, user_is_department_head: null, user_profile_pic: null});
                return false;
            }
        })
        .catch(() => {
            //console.log(error);
            Vue.prototype.$store.dispatch('auth/userInit', {authenticated: false, user_id: null, user_organization_name: null, user_name: null, user_role_id: null, user_designation_id: null, user_email: null, user_exporter_type: null, user_service_office_id: null, user_profile_pic: null});
            return false;
        })
}

async function gotoDashboard(){
    let params = {};
    if (Vue.prototype.$globalHelpers.getAuthStoreValue('user_role_id') === Vue.prototype.$globalEnums.roles.Exporter) {
        params = Vue.prototype.$globalHelpers.getAuthStoreValue('user_username') ? {
            type: 'ren',
            status: 'pending'
        } : {type: 'reg', status: 'pending'};
        await Vue.prototype.$routerGlobal.push({name: 'App.EA.List', params: params});
    }
    else if(Vue.prototype.$globalHelpers.getAuthStoreValue('user_exporter_type') === Vue.prototype.$globalEnums.ExporterTypes.Commodities){
        await Vue.prototype.$routerGlobal.push({name: 'App.SvForm.List', params: {type: 'incentive'}});
    }
    else if(Vue.prototype.$globalHelpers.getAuthStoreValue('user_designation_id') === Vue.prototype.$globalEnums.Designations.Vice_Chairman){
        await Vue.prototype.$routerGlobal.push({name: 'App.SvForm.List', params: {type: 'incentive'}});
    }
    else {
        params = {type: 'reg', status: 'pending'};
        await Vue.prototype.$routerGlobal.push({name: 'App.EA.List', params: params});
    }

    //await Vue.prototype.$routerGlobal.push({name: 'App.SvForm.List'});
}

async function logout() {
    await axios.get(Vue.prototype.$globalSettings.api.endpoints.authentication.logout, {params: {Token: Vue.prototype.$store.getters['auth/token']}})
        .then((response) => {
            if(response.data.Status === 'success'){
                Vue.prototype.$store.dispatch('auth/setToken', {token: null});
                Vue.prototype.$routerGlobal.push({name: 'Login'});
            }else{
                Vue.$toast.open({ message: response.data.Msg,  type: response.data.Status});
            }
        })
        .catch((error) => {
            console.log(error);
            Vue.$toast.open({ message: error,  type: 'error'});
        })
}

//user
function getAuthStoreValue(key) {
    return Vue.prototype.$store.getters['auth/'+key];
}

//Media
async function saveFile(file, endpoint, extraFields = {}) {
    let output = {Status: 'error', Msg: 'Something went wrong!'};

    let formData = new FormData();
    formData.append('file', file);

    for (const [key, value] of Object.entries(extraFields)) {
        if(value) formData.append(key, value);
    }

    return await axios.post(endpoint, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then((response) => {
            output.Status = response.data.Status;
            output.Msg = response.data.Msg;
            return output;
        })
        .catch((error) => {
            console.log(error);
            this.processHttpErrorsToast(error);
            return output;
        })
}

//Factory Types
async function getFactoryTypes(type = null, isAscendingOrder = false){
    let factoryTypes = Vue.prototype.$store.getters['common/factoryTypes'];

    if(factoryTypes.length <= 0){

        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.factoryType.list, {
            Label: null,
            Type: type,
            IsAscendingOrder: isAscendingOrder
        })
            .then((response) => {
                Vue.prototype.$store.dispatch('common/setFactoryTypes', {value: response.data.List});
                return response.data.List;
            })
            .catch((error) => {
                processHttpErrorsToast(error);
                return [];
            })
    }
    if(type){
        return factoryTypes.filter(x => x.Type === type);
    }
    return factoryTypes;
}
async function getTextileFactoryTypeName(id) {
    let factoryTypes = await getFactoryTypes(Vue.prototype.$globalEnums.ExporterTypes.Textile);
    if (factoryTypes.length > 0) {
        return factoryTypes.find(x => x.Id === id).Label
    }
    return '';
}
async function getNonTextileFactoryTypeName(id) {
    let factoryTypes = await getFactoryTypes(Vue.prototype.$globalEnums.ExporterTypes.NonTextile);
    if (factoryTypes.length > 0) {
        return factoryTypes.find(x => x.id === id).Label
    }
    return '';
}

//Arrays
function getDistrictName(id){
    return id !== 0 && id !== null? Vue.prototype.$globalSettings.districts.find(x => x.id === id).label: null;
}
function getThanaName(id){
    //console.log(id);
    return id !== 0 && id !== null? Vue.prototype.$globalSettings.thanas.find(x => x.id === id).label: null;
}

function pluck(array, key) {
    return array.map(function(item) { return item[key]; });
}

//Enum
function getUserRoleName(role_id) {
    return Object.keys(Vue.prototype.$globalEnums.roles).find(key => Vue.prototype.$globalEnums.roles[key] === role_id);
}
function getUserDesignationName(id) {
    let name = Object.keys(Vue.prototype.$globalEnums.Designations).find(key => Vue.prototype.$globalEnums.Designations[key] === id);
    if(name === 'None'){
        return '';
    }
    return humanize(name);
}
function getExporterTypesName(id) {
    return Object.keys(Vue.prototype.$globalEnums.ExporterTypes).find(key => Vue.prototype.$globalEnums.ExporterTypes[key] === id);
}
function getApplicationTypesName(id) {
    return Object.keys(Vue.prototype.$globalEnums.ApplicationTypes).find(key => Vue.prototype.$globalEnums.ApplicationTypes[key] === id);
}
function getApplicationAttachmentTypesName(id) {
    return Object.keys(Vue.prototype.$globalEnums.ApplicationAttachmentTypes).find(key => Vue.prototype.$globalEnums.ApplicationAttachmentTypes[key] === id);
}
function getApplicationStatusName(id) {
    return Object.keys(Vue.prototype.$globalEnums.ApplicationStatuses).find(key => Vue.prototype.$globalEnums.ApplicationStatuses[key] === id);
}

function getPaymentMethodName(id) {
    return Object.keys(Vue.prototype.$globalEnums.PaymentMethods).find(key => Vue.prototype.$globalEnums.PaymentMethods[key] === id);
}
function getPaymentInstrumentName(id) {
    return Object.keys(Vue.prototype.$globalEnums.PaymentInstruments).find(key => Vue.prototype.$globalEnums.PaymentInstruments[key] === id);
}
function getServiceOfficeName(id) {
    return Object.keys(Vue.prototype.$globalEnums.ServiceOffices).find(key => Vue.prototype.$globalEnums.ServiceOffices[key] === id);
}

function getEnumLabel(enumObject, value) {
    return Object.keys(enumObject).find(key => enumObject[key] === value);
}

function enumToArray(enumObject){
    let output = [];
    for(let key in enumObject){
        output.push({ id: enumObject[key], label: key });
    }
    return output;
}

//date time
function getDateToday(format) {
    return dayjs().format(format);
}

function getFormattedDate(date, from, to) {
    //From format:  YYYY-MM-DD H:mm:ss
    //console.log(date, from, to);
    let day = from!== '' ? dayjs(date, from): dayjs(date);
    return day.format(to);
}

export function getDateTimeToday() {
    return dayjs().format('YYYY-MM-DD HH:mm');
}

//localStorage
async function setLocalStorageItem(key, val){
    await localStorage.setItem(key, val);
    //console.log(localStorage.getItem(key));
    return true
}
async function getLocalStorageItem(key, defaultVal){
    let val = await localStorage.getItem(key);
    return val? val: defaultVal;
}

async function removeLocalStorageItem(key){
    console.log(key);
    await localStorage.removeItem(key);
    return true;
}

//print
function printDiv(elementId) {
    // Get HTML to print from element
    const prtHtml = document.getElementById(elementId).innerHTML;

    // Get all stylesheets HTML
    let stylesHtml = '';
    for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
    }

    // Open the print window
    const WinPrint = window.open('', '', 'left=0,top=0,width=1280,height=900,toolbar=0,scrollbars=0,status=0');

    WinPrint.document.write(`<!DOCTYPE html>
                <html>
                  <head>
                  <title>${this.title + ' - ' + this.id}</title>
                    ${stylesHtml}
                  </head>
                  <body style="font-size: 16px;">
                    ${prtHtml}
                  </body>
                </html>`);

    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    setTimeout(function(){WinPrint.close();}, 200);
}

//comparing
function isEmptyOrUndefined(value) {
    if(!value){
        return true;
    }
    if(value == undefined){
        return true;
    }
    if(value === ''){
        return true;
    }
    return false;
}

//error handling
function processHttpErrorsToast(error, showToast = false) {
    if (typeof error === 'string' || error instanceof String){
        if (showToast) {
            Vue.$toast.open({ message: error,  type: 'error'});
        }else{
            console.log(error);
        }
    }else if(error.response){
        if(error.response.status === 422){
            for(let err in error.response.data.errors){
                if (Object.prototype.hasOwnProperty.call(error.response.data.errors, err)) {
                    if(showToast) {
                        Vue.$toast.open({message: error.response.data.errors[err][0], type: 'error'});
                    }else console.log(error.response.data.errors[err][0]);
                }
            }
        }
    }else{
        Vue.$toast.open({ message: error,  type: 'error'});
        if(error !== undefined || error !== null) {
            if (showToast) {
                Vue.$toast.open({message: error, type: 'error'});
            } else {
                console.log(error);
            }
        }
    }
}

function getSvFormStatusName(id, formTypeId) {
    if(formTypeId === Vue.prototype.$globalEnums.SvFormTypes.Incentive){
        return Object.keys(Vue.prototype.$globalEnums.SvFormIncentiveStatuses).find(key => Vue.prototype.$globalEnums.SvFormIncentiveStatuses[key] === id);
    }
    return Object.keys(Vue.prototype.$globalEnums.SvFormCOStatuses).find(key => Vue.prototype.$globalEnums.SvFormCOStatuses[key] === id);
}
function getSvFormTypeName(id) {
    return Object.keys(Vue.prototype.$globalEnums.SvFormTypes).find(key => Vue.prototype.$globalEnums.SvFormTypes[key] === id);
}
function getUnitTypName(id) {
    return Object.keys(Vue.prototype.$globalEnums.unitTypes).find(key => Vue.prototype.$globalEnums.unitTypes[key] === id);
}
function getCurrencyName(id) {
    return Object.keys(Vue.prototype.$globalEnums.currencies).find(key => Vue.prototype.$globalEnums.currencies[key] === id);
}
function getCountryNameById(id) {
    const country = Vue.prototype.$globalSettings.countries.find(country => country.id === id);
    return country ? country.name : '';
}
function getCountryNameByCode(code) {
    const country = Vue.prototype.$globalSettings.countries.find(country => country.code === code);
    return country ? country.name : '';
}

//expression manipulations
function humanize(str) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
}

function getFormattedEXPNo(input) {
    if(input){
        return input.length === 18 ? `${input.slice(0, 8)}/${input.slice(8, 14)}/${input.slice(14)}` : '';
    }

    return null;
}


export default { signIn, userInit, gotoDashboard, logout, getAuthStoreValue, saveFile, getFactoryTypes, getDistrictName, getThanaName, pluck, getUserRoleName, getUserDesignationName, getExporterTypesName, getApplicationTypesName, getApplicationAttachmentTypesName, getApplicationStatusName, getTextileFactoryTypeName, getNonTextileFactoryTypeName, getPaymentMethodName, getPaymentInstrumentName, getServiceOfficeName, getEnumLabel, enumToArray, getDateToday, getFormattedDate, getDateTimeToday, setLocalStorageItem, getLocalStorageItem, removeLocalStorageItem, printDiv, isEmptyOrUndefined, processHttpErrorsToast, getSvFormStatusName, getSvFormTypeName, getUnitTypName, getCurrencyName, getCountryNameById, getCountryNameByCode, humanize, getFormattedEXPNo }