import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import common from "./common";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    common, auth
  }
})
