<template>
    <v-app>
        <v-main class="public-page-bg">

            <v-container fill-height fluid>
                <v-row no-gutters>
                    <v-col sm="8" offset-xl="2" offset-lg="2" offset-md="3">
                        <div class="text-center">
                            <img height="80" src="/images/logo.png" class="mr-3">
                            <img  height="80" src="/images/epb-round.png">
                            <div class="font-bold font-size-20 pt-2">
                                {{ $globalSettings.app_name_full }}
                            </div>
                        </div>

                        <v-card :loading="loading" class="mx-auto my-12 pa-5">
                            <template slot="progress">
                                <v-progress-linear
                                        :color="$globalSettings.progressbarClasses"
                                        height="10"
                                        indeterminate
                                ></v-progress-linear>
                            </template>

                            <div class=""><v-icon small>fas fa-user-plus</v-icon> {{ formData.type === 'reg'? 'রেজিস্ট্রেশন সাইনআপ': 'রিনিউ সাইনআপ' }}</div>
                            <v-divider class="mt-2"></v-divider>

                            <div class="mt-3 font-size-14" v-if="formData.type === 'reg'">আপনার প্রতিষ্ঠান যদি ইতোপূর্বে রপ্তানি উন্নয়ন ব্যুরোতে নিবন্ধিত না হয় তবে নিম্নের ফর্মটি পূরণ করে সাবমিট করুন। যদি প্রতিষ্ঠানটি পূর্বে রপ্তানি উন্নয়ন ব্যুরোতে নিবন্ধিত হয়ে থাকে (BD অথবা NT নম্বর রয়েছে) তবে নিম্নের ফর্মের পরিবর্তে <a v-on:click="changeType('ren')" class="text-underline font-bold">রিনিউ সাইনআপ</a> ফর্মটি সাবমিট করুন।</div>
                            <div class="mt-3 font-size-14" v-if="formData.type === 'ren'">আপনার প্রতিষ্ঠান যদি ইতোপূর্বে রপ্তানি উন্নয়ন ব্যুরোতে নিবন্ধিত হয়ে থাকে (BD অথবা NT নম্বর রয়েছে) তবে নিম্নের ফর্মটি পূরণ করে সাবমিট করুন। যদি প্রতিষ্ঠানটি পূর্বে রপ্তানি উন্নয়ন ব্যুরোতে নিবন্ধিত  না হয় তবে নিম্নের ফর্মের পরিবর্তে <a v-on:click="changeType('reg')" class="text-underline font-bold"> রেজিস্ট্রেশন সাইনআপ</a> ফর্মটি সাবমিট করুন। </div>
                            <v-divider class="mt-2"></v-divider>
                            <validation-observer ref="observer" v-slot="{ invalid }">
                                <form @submit.prevent="register">
                                    <v-row no-gutters>
                                        <v-col cols="12" md="3"></v-col>
                                        <v-col cols="12" md="6" class="pa-2 pt-5">
                                            <validation-provider v-slot="{ errors }" name="Registration No." rules="required|alphanumericExporterUsername" v-if="formData.type === 'ren'">
                                                <v-text-field class="mb-3" v-model="formData.Username" type="text" :error-messages="errors" label="Registration No. (BD or NT)" :disabled="loading" hint="example: BD00001" @keyup="selectExporterType()"  outlined dense persistent-hint></v-text-field>
                                            </validation-provider>

                                            <validation-provider v-slot="{ errors }" name="OrganizationName" rules="required">
                                                <v-text-field class="mb-3" v-model="formData.OrganizationName" :error-messages="errors" label="Organization Name" :disabled="loading" hint="example:  ABC Textile Ltd."  outlined dense persistent-hint required></v-text-field>
                                            </validation-provider>

                                            <ValidationProvider v-slot="{ errors }" name="ExporterType" rules="required">
                                                <v-select class="mb-3" v-model="formData.ExporterType" :error-messages="errors" :items="exporterTypes" item-value="id" item-text="label" label="Exporter Type" hint="type of your factory" outlined dense persistent-hint :disabled="loading" required></v-select>
                                            </ValidationProvider>

                                            <ValidationProvider v-slot="{ errors }" name="Service Office" rules="required">
                                                <v-select class="mb-3" v-model="formData.ServiceOfficeId" :error-messages="errors" :items="serviceOffices" item-value="id" item-text="label" label="Service Office" hint="select EPB service office" outlined dense persistent-hint :disabled="loading" required></v-select>
                                            </ValidationProvider>

                                            <validation-provider v-slot="{ errors }" name="Name" rules="required">
                                                <v-text-field class="mb-3" v-model="formData.Name" :error-messages="errors" label="Authorized Person Name" :disabled="loading" hint="example: Arif Ahmed"  outlined dense persistent-hint required></v-text-field>
                                            </validation-provider>

                                            <validation-provider v-slot="{ errors }" name="Mobile" rules="required">
                                                <v-text-field class="mb-3" v-model="formData.Mobile" :error-messages="errors" label="Authorized Person Mobile No." :disabled="loading" hint="example: 01712345678"  outlined dense persistent-hint required></v-text-field>
                                            </validation-provider>

                                            <validation-provider v-slot="{ errors }" name="Email" rules="required|email">
                                                <v-text-field class="mb-3" v-model="formData.Email" type="email" :error-messages="errors" label="Email" :disabled="loading" hint="example: ahmed@gmail.com"  outlined dense persistent-hint required></v-text-field>
                                            </validation-provider>
                                            <validation-provider v-slot="{ errors }" name="Password" rules="required|min:6" vid="Password">
                                                <v-text-field class="mb-3" v-model="formData.Password" type="password" :error-messages="errors" label="Account Password" :disabled="loading" hint="Minimum 6 characters"  outlined dense persistent-hint required></v-text-field>
                                            </validation-provider>
                                            <validation-provider v-slot="{ errors }" name="PasswordConfirmed" rules="required|confirmed:Password">
                                                <v-text-field class="mb-3" v-model="formData.PasswordConfirmed" type="password" :error-messages="errors" label="Repeat Password" :disabled="loading" hint="Type the same password again."  outlined dense persistent-hint required></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="3"></v-col>
                                    </v-row>

                                    <v-row no-gutters>
                                        <v-col sm="6">
                                            <v-card-actions>
                                                <v-btn color="primary" type="button" plain v-on:click="login()"><v-icon small>fas fa-angle-left</v-icon> Login</v-btn>
                                            </v-card-actions>
                                        </v-col>
                                        <v-col sm="6">
                                            <v-card-actions class="d-flex flex-row-reverse">
                                                <v-btn type="submit" :disabled="invalid || loading">Continue</v-btn>
                                            </v-card-actions>
                                        </v-col>
                                    </v-row>
                                </form>
                            </validation-observer>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    import Vue from 'vue';
    import { min, confirmed, required, email } from 'vee-validate/dist/rules';
    import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';

    //setInteractionMode('eager');
    extend('required', {
        ...required,
        message: '{_field_} can not be empty',
    });
    extend('email', {
        ...email,
        message: 'This field must be an email'
    });
    extend('confirmed', {
        ...confirmed,
        message: 'The passwords must match'
    });
    extend('min', {
        ...min,
        message: 'This field must be {length} characters'
    });
    extend('alphanumericExporterUsername', {
        validate(value) {
            return /^[a-zA-Z0-9]*$/.test(value);
        },
        message: '{_field_} may only contain letters and numbers'
    });

    export default {
        name: "Signup_view",
        data: () => ({
            loading: false,

            exporterTypes: [],
            serviceOffices: [],

            formData: {
                OrganizationName: null,
                ExporterType: null,
                Name: null,
                Mobile: null,
                Username: null,
                Email: null,
                Password: null,
                PasswordEncrypted: false,
                PasswordConfirmed: null,
                type: null,
            }
        }),
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        methods: {
            selectExporterType: function () {
                console.log(this.formData.Username);
                const username = this.formData.Username.toLowerCase(); // Convert to lowercase for case-insensitive comparison

                if (username.startsWith('bd')) {
                    this.formData.ExporterType = this.$globalEnums.ExporterTypes.Textile;
                }else if(username.startsWith('nt')){
                    this.formData.ExporterType = this.$globalEnums.ExporterTypes.NonTextile;
                } else {
                    console.log('Invalid username');
                    this.formData.ExporterType = null;
                }
            },
            register: async function () {
                if(this.formData.Password !== this.formData.PasswordConfirmed){
                    Vue.$toast.open({ message: 'Please type same password in the Repeat Password box!',  type: 'error'});
                    return false;
                }

                if(this.formData.type === 'ren'){
                    const username = this.formData.Username.toLowerCase();
                    if (username.startsWith('bd') && this.formData.ExporterType !== this.$globalEnums.ExporterTypes.Textile) {
                        Vue.$toast.open({ message: 'Username (BD) and Exporter Type (NonTextile) mismatch!',  type: 'error'});
                        return false;
                    }else if (username.startsWith('nt') && this.formData.ExporterType !== this.$globalEnums.ExporterTypes.NonTextile) {
                        Vue.$toast.open({ message: 'Username (NT) and Exporter Type (Textile) mismatch!',  type: 'error'});
                        return false;
                    }else if (!username.startsWith('bd') && !username.startsWith('nt')){
                        Vue.$toast.open({ message: 'Username must include BD or NT!',  type: 'error'});
                        return false;
                    }
                }

                this.loading = true;
                await this.$axios.post(this.$globalSettings.api.endpoints.authentication.signup, this.formData).then(async (response) => {
                    this.loading = false;
                    Vue.$toast.open({ message: response.data.Msg,  type: response.data.Status});
                    if(response.data.Status === 'success'){
                        sessionStorage.l = this.formData.Email;
                        sessionStorage.p = this.formData.Password;
                        //await this.$store.dispatch('auth/setVerifyEmail', {email: this.formData.Email});
                        await this.$router.push({name: 'Verify', params: { email: this.formData.Email }});
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });
            },
            login: async function () {
                await this.$router.push({name: 'Login'});
            },
            changeType: async function(type){
                await this.$router.push({name: 'Signup', params: {type: type}});
                this.$nextTick(async () => {
                    this.formData.type = type;
                })
            },
        },
        created() {
            this.exporterTypes = this.$globalHelpers.enumToArray(this.$globalEnums.ExporterTypes).filter(x => x.id <= 2 && x.id !== this.$globalEnums.ExporterTypes.None );
            this.serviceOffices = this.$globalHelpers.enumToArray(this.$globalEnums.ServiceOffices).filter(x => x.id !== this.$globalEnums.ServiceOffices.None);
            this.formData.type = this.$route.params.type;
        }
    }
</script>

<style scoped>
    .app-name{
        color: #794986;
        font-size: 20px;
    }
    .login-left-bg{

    }
    .login-right-bg{
        background: #512DA8;
    }
</style>