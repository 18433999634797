export default {
    namespaced: true,

    state: {
        factoryTypes: [],
    },

    getters: {
        factoryTypes (state) {
            return state.factoryTypes
        },
    },

    mutations: {
        SET_FACTORY_TYPES (state, value) {
            state.factoryTypes = value
        },
    },

    actions: {
        setFactoryTypes({ commit }, {value}) {
            commit('SET_FACTORY_TYPES', value);
        },
    }
}