<template>
    <v-app>
        <v-main class="public-page-bg">

            <v-container fill-height fluid>
                <v-row v-if="isValidEA">
                    <v-col sm="10" offset-xl="1" offset-lg="1" offset-md="1">

                        <v-card :loading="loading" class="mx-auto" outlined>
                            <template slot="progress">
                                <v-progress-linear
                                        :color="$globalSettings.progressbarClasses"
                                        height="10"
                                        indeterminate
                                ></v-progress-linear>
                            </template>

                            <v-card-title>
                                <v-row>
                                    <v-col cols="12" sm="4">
                                        <div class="secondary-text mb-2">
                                            Application for {{ $globalHelpers.getEnumLabel($globalEnums.ApplicationTypes, formData.ApplicationType) }}
                                        </div>
                                        <div class="font-size-12 smaller-line-height ml-8">Serial No.: <span class="font-italic">{{ formData.Id? formData.Id: ''}}</span></div>
                                        <div class="font-size-12 smaller-line-height ml-8">Type: <span class="font-italic">{{ $globalHelpers.getApplicationTypesName(formData.ApplicationType) }} {{ $globalHelpers.getEnumLabel($globalEnums.ExporterTypes, formData.User.ExporterType) }}</span> <span v-if="formData.ApplicationType === $globalEnums.ApplicationTypes.Renewal && formData.PaymentYears > 0">({{ formData.PaymentYears + ' Year' + (formData.PaymentYears > 1? 's': '') + (formData.LateRenewal? ' - Late': '') }})</span></div>
                                        <div class="font-size-12 smaller-line-height ml-8">Created: <span class="font-italic">{{ $globalHelpers.getFormattedDate(formData.Created, '', 'DD/MM/YYYY') }}</span></div>
                                        <div class="font-size-12 smaller-line-height ml-8">Updated: <span class="font-italic">{{ $globalHelpers.getFormattedDate(formData.Modified, '', 'DD/MM/YYYY') }}</span></div>
                                        <div class="font-size-12 smaller-line-height ml-8">Status: <span class="font-italic">{{ $globalHelpers.getApplicationStatusName(formData.StatusId) }}</span></div>
                                    </v-col>
                                    <v-col cols="12" sm="8" class="text-right">
                                        <div class="mb-2">{{ formData.User.OrganizationName }} <span v-if="formData.User.Username" class="font-size-14">({{ formData.User.Username }})</span></div>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-divider class="divider-inner-page"></v-divider>
                            <v-card-text style="padding-top: 12px; padding-bottom: 12px;">
                                <validation-observer ref="observerMain" v-slot="{ invalid }">
                                    <v-row>
                                        <v-col sm="3" class="border-right-grey">
                                            <v-list dense>
                                                <v-list-item @click="selectSection(100)" :class="{'v-item--active v-list-item--active v-list-item--link theme--light': selectedSectionId === 100 }">
                                                    <v-list-item-icon>
                                                        <v-icon small>fas fa-clock-rotate-left</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content><v-list-item-title>Status History</v-list-item-title></v-list-item-content>
                                                </v-list-item>
                                                <v-list-item @click="selectSection(101)" :class="{'v-item--active v-list-item--active v-list-item--link theme--light': selectedSectionId === 101, '': selectedSectionId !== 101 }">
                                                    <v-list-item-icon>
                                                        <v-icon small v-if="!invalid && formActions.selectedHSCodes.length > 0" class="success--text">fas fa-circle-check</v-icon>
                                                        <v-icon small v-else>fas fa-file-pen</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content><v-list-item-title>Form <span class="error--text">*</span></v-list-item-title></v-list-item-content>
                                                </v-list-item>
                                                <div v-for="(attachment, i) in attachments" :key="i">
                                                    <v-list-item @click="selectSection(attachment.id)" :class="{'v-item--active v-list-item--active v-list-item--link theme--light': selectedSectionId === attachment.id }">
                                                        <v-list-item-icon>
                                                            <v-icon small v-if="isAttachmentSectionComplete(attachment.id)" class="success--text">fas fa-circle-check</v-icon>
                                                            <v-icon small v-else>far fa-circle</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ attachment.label}} <span v-if="attachment.required" class="error--text">*</span></v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </div>

                                                <v-list-item @click="selectSection(102)" v-if="formData.ApplicationType !== $globalEnums.ApplicationTypes.Address_Change" :class="{'v-item--active v-list-item--active v-list-item--link theme--light': selectedSectionId === 102 }">
                                                    <v-list-item-icon>
                                                        <v-icon small v-if="formData.PaymentStatus === 'success'" class="success--text">fas fa-circle-check</v-icon>
                                                        <v-icon small v-else>fas fa-credit-card</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content><v-list-item-title>Payment <span class="error--text">*</span></v-list-item-title></v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                        <v-col sm="9">
                                            <div v-show="selectedSectionId === 100">
                                                <v-row>
                                                    <v-col cols="12" sm="6"><div class="font-size-20 font-bold">Status History and Notes</div></v-col>
                                                    <v-col cols="12" sm="6"></v-col>
                                                </v-row>
                                                <v-divider class="mt-2"></v-divider>
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead>
                                                        <tr>
                                                            <th class="text-left" width="15%">Date</th>
                                                            <th class="text-left" width="20%">Status</th>
                                                            <th class="text-left" width="20%">User</th>
                                                            <th class="text-left" width="25%">Organization</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr v-for="(eaStatusLog, i) in formData.EAStatusLogs" :key="i">
                                                            <td>{{ $globalHelpers.getFormattedDate(eaStatusLog.Created, '', 'MMMM D, YYYY') }}<br>{{ $globalHelpers.getFormattedDate(eaStatusLog.Created, '', 'hh:mm:ss A') }}</td>
                                                            <td>{{ $globalHelpers.humanize($globalHelpers.getApplicationStatusName(eaStatusLog.StatusId)) }}</td>
                                                            <td>
                                                                {{ eaStatusLog.CreatedByUser.Name }}
                                                                <div v-if="eaStatusLog.CreatedByUser.DesignationId" class="font-size-12 font-italic">{{ $globalHelpers.getUserDesignationName(eaStatusLog.CreatedByUser.DesignationId) }}</div>
                                                            </td>
                                                            <td>{{ eaStatusLog.CreatedByUser.OrganizationName }}</td>
                                                        </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </div>
                                            <div v-show="selectedSectionId === 101">
                                                <v-row>
                                                    <v-col cols="12" sm="6"><div class="font-size-20 font-bold">Company Details</div></v-col>
                                                    <v-col cols="12" sm="6"><v-btn type="button" class="py-3 ml-1 float-end" small color="secondary" outlined @click="pdfApplication" :disabled="loading"><v-icon small class="mr-1">fas fa-print</v-icon> Print Application</v-btn></v-col>
                                                </v-row>
                                                <v-divider class="mt-2 mb-5"></v-divider>
                                                <v-form @submit.prevent="save" :disabled="!formActions.formEditEnabled" ref="companyInfoForm">
                                                    <v-row>
                                                        <v-col cols="12" sm="3">
                                                            <ValidationProvider v-slot="{ errors }" name="ExporterType" rules="required">
                                                                <v-select v-model="formData.User.ExporterType" :error-messages="errors" :items="formActions.exporterTypes" item-value="id" item-text="label" label="Exporter Type *" persistent-hint :disabled="loading" @change="prepareAttachments()" required></v-select>
                                                            </ValidationProvider>
                                                        </v-col>
                                                        <v-col cols="12" sm="3">
                                                            <validation-provider v-slot="{ errors }" name="TIN No" rules="required|length:12|numeric">
                                                                <v-text-field v-model="formData.TINNo" type="text" :error-messages="errors" label="Tin No *" :disabled="loading" required hint="ex. 123456789012"></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" sm="3">
                                                            <validation-provider v-slot="{ errors }" name="BIN No" rules="required">
                                                                <v-text-field v-model="formData.BINNo" type="text" :error-messages="errors" label="Bin No *" :disabled="loading" required></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" sm="3" v-if="formData.ApplicationType !== $globalEnums.ApplicationTypes.Registration">
                                                            <validation-provider v-slot="{ errors }" name="Rex No">
                                                                <v-text-field v-model="formData.RexNo" type="text" :error-messages="errors" label="Rex No" :disabled="loading"></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" sm="3">
                                                            <validation-provider v-slot="{ errors }" name="Service Office" rules="required">
                                                                <v-select v-model="formData.ServiceOfficeId" :items="$globalHelpers.enumToArray($globalEnums.ServiceOffices)" item-value="id" item-text="label" label="Service Office *" :error-messages="errors" :disabled="loading"></v-select>
                                                            </validation-provider>
                                                        </v-col>
                                                    </v-row>


                                                    <div class="mt-10">Office Information</div>
                                                    <v-row>
                                                        <v-col cols="12" sm="6">
                                                            <validation-provider v-slot="{ errors }" name="OfficeAddress" rules="required">
                                                                <v-text-field v-model="formData.OfficeAddress" type="text" :error-messages="errors" label="Address *" :disabled="loading" required></v-text-field>
                                                            </validation-provider>
                                                            <validation-provider v-slot="{ errors }" name="OfficeEmail" rules="required|email">
                                                                <v-text-field v-model="formData.OfficeEmail" type="text" :error-messages="errors" label="Email *" :disabled="loading" required></v-text-field>
                                                            </validation-provider>
                                                            <validation-provider v-slot="{ errors }" name="OfficePhone" rules="required">
                                                                <v-text-field v-model="formData.OfficePhone" type="text" :error-messages="errors" label="Phone *" :disabled="loading" required></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" sm="6">
                                                            <validation-provider v-slot="{ errors }" name="District" rules="required">
                                                                <v-select v-model="formData.OfficeDistrictId" :items="$globalSettings.districts" item-value="id" item-text="label" label="District *" :error-messages="errors" :disabled="loading"></v-select>
                                                            </validation-provider>
                                                            <validation-provider v-slot="{ errors }" name="District" rules="required">
                                                                <v-select v-model="formData.OfficeThanaId" :items="$globalSettings.thanas.filter(x => x.districtId === formData.OfficeDistrictId)" item-value="id" item-text="label" label="Thana *" :error-messages="errors" :disabled="loading"></v-select>
                                                            </validation-provider>
                                                            <v-text-field v-model="formData.OfficeWebsite" type="text" label="Website" :disabled="loading"></v-text-field>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row class="mt-10">
                                                        <v-col cols="12" sm="6">
                                                            <div>Factory Information</div>
                                                        </v-col>
                                                        <v-col cols="12" sm="6">
                                                            <v-btn depressed color="primary" @click="formCopyOfficeToFactory" x-small v-if="formActions.formEditEnabled"><v-icon small x-small class="mr-2">far fa-copy</v-icon>Copy from Office</v-btn>
                                                        </v-col>
                                                        <v-col cols="12" sm="6">
                                                            <validation-provider v-slot="{ errors }" name="FactoryAddress" rules="required">
                                                                <v-text-field v-model="formData.FactoryAddress" type="text" :error-messages="errors" label="Address *" :disabled="loading" required></v-text-field>
                                                            </validation-provider>
                                                            <validation-provider v-slot="{ errors }" name="FactoryEmail" rules="required|email">
                                                                <v-text-field v-model="formData.FactoryEmail" type="text" :error-messages="errors" label="Email *" :disabled="loading" required></v-text-field>
                                                            </validation-provider>
                                                            <validation-provider v-slot="{ errors }" name="FactoryPhone" rules="required">
                                                                <v-text-field v-model="formData.FactoryPhone" type="text" :error-messages="errors" label="Phone *" :disabled="loading" required></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" sm="6">
                                                            <validation-provider v-slot="{ errors }" name="District" rules="required">
                                                                <v-select v-model="formData.FactoryDistrictId" :items="$globalSettings.districts" item-value="id" item-text="label" label="District *" :error-messages="errors" :disabled="loading"></v-select>
                                                            </validation-provider>
                                                            <validation-provider v-slot="{ errors }" name="District" rules="required">
                                                                <v-select v-model="formData.FactoryThanaId" :items="$globalSettings.thanas.filter(x => x.districtId === formData.FactoryDistrictId)" item-value="id" item-text="label" label="Thana *" :error-messages="errors" :disabled="loading"></v-select>
                                                            </validation-provider>
                                                            <v-text-field v-model="formData.FactoryWebsite" type="text" label="Website" :disabled="loading"></v-text-field>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row class="mt-5" v-if="formData.ApplicationType !== $globalEnums.ApplicationTypes.Address_Change">
                                                        <v-col cols="12" :sm="formActions.formEditEnabled ? 6: 12">
                                                            Products/HS Codes
                                                            <div class="mt-2" style="min-height: 300px;">
                                                                <v-row v-for="(hsCode, i) in formActions.selectedHSCodes" :key="i">
                                                                    <v-col sm="2">
                                                                        {{ hsCode.Code }}
                                                                    </v-col>
                                                                    <v-col sm="8">
                                                                        {{ hsCode.Description }}
                                                                    </v-col>
                                                                    <v-col sm="2" v-if="formActions.formEditEnabled">
                                                                        <v-btn fab x-small type="button" class="mr-3" :disabled="loading" @click="removeHSCode(hsCode)"><v-icon x-small>fas fa-minus</v-icon></v-btn>
                                                                    </v-col>
                                                                </v-row>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" sm="6" v-if="formActions.formEditEnabled">
                                                            <v-text-field v-model="formActions.hscodeSearchKeyword" type="text" label="Search & Add HS code" @keyup="searchHSCode"></v-text-field>
                                                            <div class="mt-2" style="min-height: 300px;">
                                                                <v-row v-for="(hsCode, i) in formActions.hsCodeSearchResults" :key="i">
                                                                    <v-col sm="2">
                                                                        {{ hsCode.Code }}
                                                                    </v-col>
                                                                    <v-col sm="8">
                                                                        {{ hsCode.Description }}
                                                                    </v-col>
                                                                    <v-col sm="2">
                                                                        <v-btn fab x-small type="button" class="mr-3" :disabled="loading" @click="addHSCode(hsCode)"><v-icon x-small>fas fa-plus</v-icon></v-btn>
                                                                    </v-col>
                                                                </v-row>
                                                                <i v-if="formActions.hscodeSearchKeyword && formActions.hsCodeSearchResults.length < 1">No HS Code matched your keyword.</i>
                                                            </div>
                                                        </v-col>

                                                    </v-row>

                                                    <v-row class="mt-10" v-if="formActions.formEditEnabled">
                                                        <v-col cols="12" sm="12">
                                                            <div>Other Information</div>
                                                            <v-textarea outlined rows="3" v-model="formData.ExporterNote" label="Exporter Note" hint="If you have any comment to make, please write it down here" class="mt-5"></v-textarea>
                                                        </v-col>
                                                    </v-row>

                                                    <v-card-actions class="d-flex flex-row-reverse">
                                                        <v-btn v-if="formData.ApplicationType === $globalEnums.ApplicationTypes.Address_Change && formActions.formEditEnabled && !invalid && isRequiredAttachmentsUploaded" type="button" class="success" :disabled="invalid || loading" @click="prepareStatusChange($globalEnums.ApplicationStatuses.Director_desk)">Submit Application</v-btn>
                                                        <v-btn v-if="formActions.formEditEnabled" type="submit" class="secondary mr-3" :disabled="invalid || loading">Save</v-btn>
                                                    </v-card-actions>
                                                </v-form>
                                            </div>
                                            <div v-show="selectedSectionId === 102">
                                                <v-row>
                                                    <v-col cols="12" sm="6"><div class="font-size-20 font-bold">Payment</div></v-col>
                                                    <v-col cols="12" sm="6"><v-btn type="button" class="py-3 ml-1 float-end" small color="secondary" @click="pdfPayment" :disabled="loading" v-if="formData.PaymentStatus"><v-icon small>fas fa-print</v-icon></v-btn></v-col>
                                                </v-row>
                                                <v-divider class="mt-2 mb-5"></v-divider>
                                                <div v-if="formActions.formEditEnabled && formActions.allowedPaymentOption">
                                                    <div class="font-italic" v-if="!isRequiredAttachmentsUploaded">Please upload all the required (*) attachments to make payment!</div>
                                                    <div class="font-italic" v-else-if="invalid || formActions.selectedHSCodes.length < 1">Please complete the Form section to make payment!</div>
                                                    <div v-else>
                                                        <v-row>
                                                            <!--<v-col cols="12" sm="6" v-if="formData.ApplicationType === $globalEnums.ApplicationTypes.Registration">-->
                                                            <v-col cols="12" sm="6">
                                                                <v-card>
                                                                    <v-card-title>Pay Online with Card/Mobile Banking</v-card-title>
                                                                    <v-card-text>
                                                                        <v-row>
                                                                            <v-col cols="12" sm="4" v-if="formData.ApplicationType === $globalEnums.ApplicationTypes.Renewal">
                                                                                <v-select v-model="renewalYears" :items="renewalYearsList" label="Year(s)" dense :disabled="loading || !formActions.allowPaymentYearsEdit"></v-select>
                                                                            </v-col>
                                                                            <v-col cols="12" sm="8">
                                                                                <v-btn type="button" @click="requestSPG" :disabled="loading"><img src="images/pay-bkash.png" class="mr-2" /> <img src="images/pay-visa.png" class="mr-2" /> <img src="images/pay-master.png" class="mr-2" /> Pay Online</v-btn>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-card-text>
                                                                </v-card>

                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                </div>
                                                <div v-else class="font-italic error--text">{{ formActions.paymentErrorMsg }}</div>

                                                <v-card elevation="1" v-if="formData.PaymentStatus" class="mt-5" max-width="500">
                                                    <v-card-title>Payment Details</v-card-title>
                                                    <v-card-text>
                                                        <v-simple-table>
                                                            <template v-slot:default>
                                                                <tbody>
                                                                <tr>
                                                                    <td>Status</td><td :class="{'red--text': formData.PaymentStatus !== 'success'}">{{ formData.PaymentStatus }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Year(s)</td><td>{{ formData.ApplicationType === $globalEnums.ApplicationTypes.Renewal? formData.PaymentYears: 1 }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Method</td><td>{{ $globalHelpers.getPaymentInstrumentName(formData.PaymentInstrument) }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>TransactionId</td><td>{{ formData.PaymentTransactionId }}</td>
                                                                </tr>
                                                                </tbody>
                                                            </template>
                                                        </v-simple-table>
                                                    </v-card-text>
                                                </v-card>

                                            </div>
                                            <div v-show="selectedSectionId < 100">
                                                <div class="font-size-20 font-bold">{{ currentAttachmentItem.label }}</div>
                                                <v-divider class="mt-2 mb-5"></v-divider>

                                                <v-row>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field v-model="currentAttachmentData.attachmentDataNumber" v-if="currentAttachmentItem.numberRequired" type="text" label="Number" :disabled="!formActions.formEditEnabled" prepend-icon="fas fa-hashtag"></v-text-field>

                                                        <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" v-if="currentAttachmentItem.dateRequired">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field :value="currentAttachmentData.attachmentDataDate && $globalHelpers.getFormattedDate(currentAttachmentData.attachmentDataDate, '', 'MMMM D, YYYY')" label="Date" readonly v-bind="attrs" v-on="on" :disabled="!formActions.formEditEnabled" clearable @click:clear="currentAttachmentData.attachmentDataDate = null" prepend-icon="fa-regular fa-calendar"></v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="currentAttachmentData.attachmentDataDate"></v-date-picker>
                                                        </v-menu>


                                                        <v-file-input v-if="formActions.formEditEnabled" show-size :clearable="true" truncate-length="100" :label="currentAttachmentItem.formatLabel + '. Max 1 Megabyte'" :accept="currentAttachmentItem.formats" @change="uploadImage" v-model="attachment.attachmentInputFileName" ref="attachmentInput"></v-file-input>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">

                                                    </v-col>
                                                </v-row>

                                                <div class="images mt-10" v-viewer="{movable: false}" v-for="item in uploadedImageAttachments" v-bind:key="item.id" v-show="selectedSectionId === item.Type">
                                                    <div class="attachmentImageContainer">
                                                        <img v-if="item.MimeType !== 'application/pdf'" :aspect-ratio="16/9" :src="$apiBaseURL + $globalSettings.api.endpoints.media.attachments + item.FilePath" class="attachmentImage">
                                                        <div>
                                                            <v-btn v-if="formActions.formEditEnabled" type="button" fab x-small class="error pl-1 pr-0" @click="deleteAttachment(item.Id)"><v-icon small>fas fa-minus</v-icon></v-btn></div>
                                                    </div>

                                                </div>
                                                <div class="clearfix"></div>
                                                <v-row class="mt-10">
                                                    <v-col v-for="attachment in uploadedPdfAttachments" v-bind:key="attachment.id" v-show="selectedSectionId === attachment.Type" class="attachment attachmentPdfContainer">
                                                        <iframe :src="$apiBaseURL + $globalSettings.api.endpoints.media.attachments + attachment.FilePath" width="100%" height="500"></iframe>
                                                        <v-btn v-if="formActions.formEditEnabled" type="button" fab x-small class="error pl-1 pr-0" @click="deleteAttachment(attachment.Id)"><v-icon small>fas fa-minus</v-icon></v-btn>
                                                    </v-col>
                                                </v-row>

                                            </div>

                                        </v-col>

                                    </v-row>

                                </validation-observer>
                            </v-card-text>
                        </v-card>

                        <vue-html2pdf
                                :show-layout="false"
                                :float-layout="true"
                                :enable-download="false"
                                :preview-modal="true"
                                :paginate-elements-by-height="1100"
                                :filename="'Application #' + formData.Id"
                                :pdf-quality="2"
                                :manual-pagination="true"
                                pdf-format="a4"
                                pdf-orientation="portrait"
                                pdf-content-width="800px"

                                @progress="pdfAppProgress($event)"
                                @hasStartedGeneration="pdfAppStarted()"
                                @hasGenerated="pdfAppCompleted($event)"
                                ref="html2PdfApp"
                        >
                            <section slot="pdf-content" class="pdftemplate">
                                <section class="pdf-item pdftemplate-heading">
                                    <img src="/images/logo.png" class="mr-3">
                                    <img  src="/images/epb-round.png">
                                    <div class="pdftemplate-heading-title">Export Promotion Bureau</div>
                                    <div class="pdftemplate-subheading">{{ formData.User.ExporterType === $globalEnums.ExporterTypes.Textile? 'Textile': 'Policy & Planning' }} Division</div>
                                    <div class="pdftemplate-address">TCB Building (5th Floor), 1 Kawran Bazar, Dhaka 1215</div>
                                </section>
                                <section class="">
                                    <div class="eapdf-body-heading-item-left">Application for Exporter {{ $globalHelpers.getEnumLabel($globalEnums.ApplicationTypes, formData.ApplicationType) }}:</div>
                                    <div class="eapdf-body-heading-item-right font-size-12">Printed: <span class="font-italic">{{ $globalHelpers.getDateToday('DD/MM/YYYY h:mm:ss A') }}</span></div>
                                    <div class="eapdf-body-heading-item-right font-size-12 mr-5" style="margin-right: 5px;">Status: <span class="font-italic font-bold">{{ $globalHelpers.getEnumLabel($globalEnums.ApplicationStatuses, formData.StatusId) }}</span> </div>
                                    <div class="clearfix"></div>
                                </section>
                                <div class="pdftemplate-vertical-spacer"></div>
                                <table border="1" cellspacing="0" class="eapdf-table">
                                    <tr>
                                        <td width="35%">Organization:</td>
                                        <td>{{ formData.User.OrganizationName }} <span v-if="formData.User.Username">({{formData.User.Username}})</span></td>
                                    </tr>
                                    <tr>
                                        <td>Office:</td>
                                        <td>{{ formData.OfficeAddress }} <br> {{ formData.OfficePhone }} <br> {{ formData.OfficeEmail }}<br> {{ formData.OfficeWebsite }}</td>
                                    </tr>
                                    <tr>
                                        <td>Factory:</td>
                                        <td>{{ formData.FactoryAddress }} <br> {{ formData.FactoryPhone }} <br> {{ formData.FactoryEmail }}<br> {{ formData.FactoryWebsite }}</td>
                                    </tr>
                                    <tr>
                                        <td>Products:</td>
                                        <td>
                                            <v-row v-for="(hsCode, i) in formActions.selectedHSCodes" :key="i" style="margin-top: 0px;">
                                                <v-col cols="12" sm="1">
                                                    {{ hsCode.Code }}
                                                </v-col>
                                                <v-col cols="12" sm="9">
                                                    {{ hsCode.Description }}
                                                </v-col>
                                            </v-row>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Payment:</td>
                                        <td>Status: {{ formData.PaymentStatus }}<br>Method: {{ formData.PaymentInstrument }}<br>Transaction #: {{ formData.PaymentTransactionId }}</td>
                                    </tr>
                                </table>
                                <div class="pdftemplate-vertical-spacer"></div>
                                <div>Attachments:</div>
                                <table border="1" cellspacing="0" class="eapdf-table">
                                    <tr v-for="(attachment, i) in attachmentsWithDataRequired" :key="i">
                                        <td width="35%">{{ attachment.label}}</td>
                                        <td>
                                            # {{ attachment.Number }}<br>
                                            Date: {{ attachment.Date? $globalHelpers.getFormattedDate(attachment.Date, '', 'DD/MM/YYYY'): '' }}
                                        </td>
                                    </tr>
                                </table>

                                <div style="margin-top: 50px; border-bottom: 1px solid black; display: inline-block">Signature</div>
                            </section>
                        </vue-html2pdf>

                        <vue-html2pdf
                                :show-layout="false"
                                :float-layout="true"
                                :enable-download="false"
                                :preview-modal="true"
                                :paginate-elements-by-height="1100"
                                :filename="'payment receipt EA #' + formData.Id"
                                :pdf-quality="2"
                                :manual-pagination="true"
                                pdf-format="a4"
                                pdf-orientation="portrait"
                                pdf-content-width="800px"

                                @progress="pdfAppProgress($event)"
                                @hasStartedGeneration="pdfAppStarted()"
                                @hasGenerated="pdfAppCompleted($event)"
                                ref="html2PdfPay"
                        >
                            <section slot="pdf-content" class="pdftemplate">
                                <section class="pdf-item pdftemplate-heading">
                                    <img src="/images/logo.png" class="mr-3">
                                    <img  src="/images/epb-round.png">
                                    <div class="pdftemplate-heading-title">Export Promotion Bureau</div>
                                    <div class="pdftemplate-subheading">{{ formData.User.ExporterType === $globalEnums.ExporterTypes.Textile? 'Textile': 'Policy & Planning' }} Division</div>
                                    <div class="pdftemplate-address">TCB Building (5th Floor), 1 Kawran Bazar, Dhaka 1215</div>
                                </section>
                                <section class="">
                                    <div class="eapdf-body-heading-item-left">Payment Confirmation of {{ $globalHelpers.getEnumLabel($globalEnums.ApplicationTypes, formData.ApplicationType) }}:</div>
                                    <div class="eapdf-body-heading-item-right font-size-12">Printed: <span class="font-italic">{{ $globalHelpers.getDateToday('DD/MM/YYYY h:mm:ss A') }}</span></div>
                                    <div class="clearfix"></div>
                                </section>
                                <div class="pdftemplate-vertical-spacer"></div>
                                <table border="1" cellspacing="0" class="eapdf-table">
                                    <tr>
                                        <td width="35%">Application #:</td>
                                        <td>{{ formData.Id }}</td>
                                    </tr>
                                    <tr>
                                        <td width="35%">Exporter No: </td>
                                        <td>{{formData.User.Username}}</td>
                                    </tr>
                                    <tr>
                                        <td>Amount:</td>
                                        <td>{{ formData.PaymentAmount }} TK</td>
                                    </tr>
                                    <tr>
                                        <td>Charged:</td>
                                        <td>{{ formData.PaymentCharged }} TK</td>
                                    </tr>
                                    <tr>
                                        <td>Status:</td>
                                        <td>{{ formData.PaymentStatus }}</td>
                                    </tr>
                                    <tr>
                                        <td>Gateway:</td>
                                        <td>{{ formData.PaymentMethod }}</td>
                                    </tr>
                                    <tr>
                                        <td>Method:</td>
                                        <td>{{ formData.PaymentInstrument }}</td>
                                    </tr>
                                    <tr>
                                        <td>Transaction #:</td>
                                        <td>{{ formData.PaymentTransactionId }}</td>
                                    </tr>
                                    <tr>
                                        <td>Message:</td>
                                        <td>{{ formData.PaymentMessage }}</td>
                                    </tr>
                                    <tr>
                                        <td>Date:</td>
                                        <td>{{ $globalHelpers.getFormattedDate(formData.PaymentDate, '', 'DD/MM/YYYY') }}</td>
                                    </tr>
                                </table>
                            </section>
                        </vue-html2pdf>

                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col sm="10" offset-xl="1" offset-lg="1" offset-md="1">

                        <v-card :loading="loading" class="mx-auto" outlined>
                            <template slot="progress">
                                <v-progress-linear
                                        :color="$globalSettings.progressbarClasses"
                                        height="10"
                                        indeterminate
                                ></v-progress-linear>
                            </template>
                            <v-card-text style="padding-top: 12px; padding-bottom: 12px; color: red; font-style: italic;" class="text-center">
                                {{ invalidEAMsg }}
                            </v-card-text>
                        </v-card>
                    </v-col>

                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import Vue from 'vue';
import { required, email, min, min_value, numeric, length } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';

import 'viewerjs/dist/viewer.css';
import { directive as viewer } from "v-viewer"

import VueHtml2pdf from 'vue-html2pdf'

//setInteractionMode('eager');
extend('required', {
    ...required,
    message: '{_field_} can not be empty',
});
extend('email', {
    ...email,
    message: 'This field must be an email'
});
extend('min', {
    ...min,
    message: 'This field must be {length} characters'
});
extend('min_value', {
    ...min_value,
    message: 'This field must be greater than {min}'
});
extend('numeric', {
    ...numeric,
    message: 'This field must be a number'
});
extend('length', {
    ...length,
    message: 'This field must be exactly {length} characters'
});

export default {
    name: "BIDAApplication_view",
    data: () => ({
        loading: false,

        isValidEA: false,
        invalidEAMsg: "",

        selectedSectionId: 0,

        formActions: {
            exporterTypes: [],
            formEditEnabled: false,
            paymentOptionEnabled: false,
            permissionMap: [],
            allowedStatuses: [],
            allowedPaymentOption: false,
            allowPaymentYearsEdit: false,
            paymentErrorMsg: null,
            payslipNumber: null,
            payslipExporterNo: null,
            payslipTemporaryExporter: false,
            nextStatusId: null,
            viewStatusChangeModal: false,
            statusChangeUsername: null,
            statusChangeComment: null,
            statusChangeBinNo: null,
            statusChangeTinNo: null,
            statusChangeFactoryTypes: [],
            searchFactoryTypeKeyword: null,
            filteredFactoryTypes: [],
            generateAutomaticInspection: false,
            viewInspectionModal: false,
            inspectionModalTitle: 'Inspection Team',
            availableInspectors: [],
            selectedInspectors: [],
            searchInspectionOfficerKeyword: null,
            filteredInspectors: [],
            selectedTeamLeader: null,
            hscodeSearchKeyword: null,
            hsCodeSearchResults: [],
            selectedHSCodes: [],
            status_change_msg_acdn_open: false,
            viewAdminEAUpdateModal: false,
            noteModal:{
                show: false,
                note: null
            },
            viewExporterUpdateModal: false,

            rootUrl: '',
        },

        attachments: [],

        attachment:{
            attachmentInputFileName: null,
        },

        currentAttachmentData:{
            attachmentDataId: null,
            attachmentDataNumber: null,
            attachmentDataDate: null,
        },

        formData: {
            Id: null,
            OfficeAddress: null,
            OfficeDistrictId: null,
            OfficeThanaId: null,
            OfficePhone: null,
            OfficeEmail: null,
            OfficeWebsite: null,
            FactoryAddress: null,
            FactoryDistrictId: null,
            FactoryThanaId: null,
            FactoryPhone: null,
            FactoryEmail: null,
            FactoryWebsite: null,
            ProductsDescription: null,
            PaymentYears: null,
            PaymentStatus: null,
            PaymentMethod: null,
            PaymentInstrument: null,
            PaymentRequestNo: null,
            PaymentRequestDate: null,
            PaymentTransactionId: null,
            TransactionStatus: null,
            PaymentAmount: null,
            PaymentCharged: null,
            PaymentMessage: null,
            PaymentDate: null,
            ApplicationType: null,
            StatusId: null,
            Created: null,
            CreatedBy: null,
            Modified: null,
            ModifiedBy: null,
            IsDeleted: null,
            DeletionTime: null,
            DeletedBy: null,
            UserId: null,
            ServiceOfficeId: 1,
            TINNo: null,
            BINNo: null,
            RexNo: null,
            ExporterNote: null,
            ValidFromDate: null,
            ValidTillDate: null,
            LateRenewal: 0,
            EAAttachmentDatas: [],
            EAAttachments: [],
            EAStatusLogs: [],
            EAInspectors: [],
            EAInspections: [],
            EAInspectionId: null,
            User:{
                OrganizationName: null,
                ExporterType: null,
                Username: null,
                BINNo: null,
                TINNo: null,
            },
            ValidFrom: 0,
            ValidTill: 0,
            HSCodeMappings: [],
        },
        renewalYears: 1,
        renewalYearsList:[0, 1, 2, 3, 4, 5],
        years:[2018,2019,2020,2021,2022,2023,2024,2025],

        printModal: false,
    }),
    components: {
        ValidationProvider,
        ValidationObserver,
        VueHtml2pdf,
    },
    directives: {
        viewer: viewer({
            debug: false,
        }),
    },
    computed:{
        currentAttachmentItem: function(){
            if(this.selectedSectionId < 100) {
                return this.attachments.find(o => o.id === this.selectedSectionId);
            }
            return {};
        },
        isRequiredAttachmentsUploaded: function () {
            for(let i = 0; i < this.attachments.length; i++){
                if(this.attachments[i].required){
                    if(this.formData.EAAttachments.filter(e => e.Type === this.attachments[i].id).length < 1){
                        return false;
                    }
                }
            }
            return true;
        },
        uploadedImageAttachments: function () {
            let items = [];
            for(let i = 0; i < this.formData.EAAttachments.length; i++){
                if(this.formData.EAAttachments[i].MimeType !== 'application/pdf') {
                    items.push(this.formData.EAAttachments[i]);
                }
            }
            return items;
        },
        uploadedPdfAttachments: function () {
            let items = [];
            for(let i = 0; i < this.formData.EAAttachments.length; i++){
                if(this.formData.EAAttachments[i].MimeType === 'application/pdf') {
                    items.push(this.formData.EAAttachments[i]);
                }
            }
            return items;
        },
        attachmentsWithDataRequired: function () {
            let output = this.attachments.filter(o => o.numberRequired || o.dateRequired);
            for(let i = 0; i < output.length; i++){
                if(this.formData.EAAttachmentDatas) {
                    let formAttachmentData = this.formData.EAAttachmentDatas.find(o => o.Type === output[i].id);
                    output[i].Number = formAttachmentData ? formAttachmentData.Number : null;
                    output[i].Date = formAttachmentData ? formAttachmentData.Date : null;
                }else{
                    output[i].Number = null;
                    output[i].Date = null;
                }
            }
            return output;
        },
    },
    methods: {
        selectSection: function(sectionId){
            this.selectedSectionId = sectionId;
            if(sectionId < 100) {
                let attachmentDataItem = this.formData.EAAttachmentDatas.find(o => o.Type === this.selectedSectionId);

                this.currentAttachmentData.attachmentDataId = attachmentDataItem? attachmentDataItem.attachmentDataId: null;
                this.currentAttachmentData.attachmentDataNumber = attachmentDataItem? attachmentDataItem.Number: null;
                this.currentAttachmentData.attachmentDataDate = attachmentDataItem? (attachmentDataItem.Date? this.$globalHelpers.getFormattedDate(attachmentDataItem.Date, '', 'YYYY-MM-DD'): null): null;
            }else{
                this.currentAttachmentData.attachmentDataId = null;
                this.currentAttachmentData.attachmentDataNumber = null;
                this.currentAttachmentData.attachmentDataDate = null;
            }
        },
        save: async function () {
            this.loading = true;
            this.prepareFormDataHSCodes();
            await this.$axios.post(this.$globalSettings.api.endpoints.ea.bida.saveBIDAEA, this.formData).then(async (response) => {
                this.loading = false;
                Vue.$toast.open({ message: response.data.Msg,  type: response.data.Status});
                if(response.data.Status === 'success'){
                    await this.loadSavedData();
                }
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            });
        },

        loadSavedData: async function () {
            this.loading = true;
            await this.$axios.post(this.$globalSettings.api.endpoints.ea.bida.getBIDAEA, {
                Id: this.$route.params.id,
                Token: this.$route.params.token
            }).then(async (response) => {
                this.loading = false;
                if (response.data.Status === 'success') {
                    this.isValidEA = true;
                    this.formData = response.data.Data;
                    this.formActions.rootUrl = response.data.RootURL;
                    this.prepareAttachments();
                    await this.prepareFormAction();

                    this.preparePayment();
                    this.prepareSelectedHSCodes();
                    this.prepareAdditionalData();
                    await this.executeTabChanges();
                }else{
                    Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                    this.invalidEAMsg = response.data.Msg;
                }
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            });
        },
        prepareFormAction: async function () {
            this.formActions.allowedStatuses = [];
            this.formActions.allowedPaymentOption = false;
            this.formActions.formEditEnabled = false;

            if (this.formData.StatusId === this.$globalEnums.ApplicationStatuses.New) {
                this.formActions.formEditEnabled = true;
            }

            if (this.formData.ApplicationType !== this.$globalEnums.ApplicationTypes.Address_Change && this.formActions.formEditEnabled) {
                if(this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Renewal){
                    if(this.formData.PaymentYears > 0){
                        this.formActions.allowedPaymentOption = true;
                    }else{
                        this.formActions.paymentErrorMsg = 'Invalid Renewal Years. Please contact help desk.';
                    }
                }else{
                    this.formActions.allowedPaymentOption = true;
                }
                this.formActions.allowPaymentYearsEdit = !this.formData.LateRenewal && this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Renewal;
            }
        },
        prepareAttachments: function (){
            this.attachments = [];
            let attachmentsTemp = [];
            if(this.formData.User.ExporterType === this.$globalEnums.ExporterTypes.Textile){
                attachmentsTemp = this.$globalSettings.ea_textile_attachments;
            }
            if(this.formData.User.ExporterType === this.$globalEnums.ExporterTypes.NonTextile){
                attachmentsTemp = this.$globalSettings.ea_non_textile_attachments;
            }
            for(let i = 0; i < attachmentsTemp.length; i++ ){
                if(
                    ((attachmentsTemp[i].applicationType === 'Both' || attachmentsTemp[i].applicationType === 'Registration') && this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Registration) ||
                    ((attachmentsTemp[i].applicationType === 'Both' || attachmentsTemp[i].applicationType === 'Renewal') && this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Renewal)
                ){
                    this.attachments.push(attachmentsTemp[i]);
                }
            }
        },
        isAttachmentSectionComplete: function (id) {
            return this.formData.EAAttachments.filter(e => e.Type === id).length > 0;
        },
        uploadImage: async function (file) {
            if(file) {
                let save = true;
                if(this.currentAttachmentItem.numberRequired && !this.currentAttachmentData.attachmentDataNumber){
                    save = false;
                }
                if(this.currentAttachmentItem.dateRequired && !this.currentAttachmentData.attachmentDataDate){
                    save = false;
                }

                if(save) {
                    this.loading = true;
                    let fileUpload = await this.$globalHelpers.saveFile(file, this.$globalSettings.api.endpoints.ea.bida.saveBIDAEAAttachment, {
                        eaId: this.formData.Id,
                        type: this.selectedSectionId,
                        attachmentDataId: this.currentAttachmentData.attachmentDataId,
                        attachmentDataNumber: this.currentAttachmentData.attachmentDataNumber,
                        attachmentDataDate: this.currentAttachmentData.attachmentDataDate,
                        token: this.$route.params.token
                    });
                    this.loading = false;
                    Vue.$toast.open({message: fileUpload.Msg, type: fileUpload.Status});
                    this.attachment.attachmentInputFileName = null;
                    await this.loadSavedData();
                }else{
                    this.attachment.attachmentInputFileName = null;
                    Vue.$toast.open({message: 'Please Fill the required fields!', type: 'error'});
                }
            }
        },
        deleteAttachment: async function (id) {
            if (confirm("Are your sure that you want to delete this file?") === true) {
                this.loading = true;
                await this.$axios.get(this.$globalSettings.api.endpoints.ea.bida.deleteBIDAEAAttachment, {params: {id: id, eaId: this.formData.Id, token: this.$route.params.token}}).then(async (response) => {
                    this.loading = false;
                    Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                    if (response.data.Status === 'success') {
                        await this.loadSavedData();
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });
            }
        },
        requestSPG: async function () {
            if(this.renewalYears !== null /* || (this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Registration || this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Address_Change)*/) {
                this.formData.RenewalYears = this.renewalYears;
                await this.save();
                this.loading = true;
                await this.$axios.get(this.$globalSettings.api.endpoints.spg.getSessionTokenForBIDA, {
                    params: {
                        eaId: this.formData.Id,
                        years: this.renewalYears,
                        token: this.$route.params.token
                    }
                }).then(async (response) => {
                    //console.log(response.data.status);
                    if (response.data.status === '200' && response.data.message === 'success') {
                        window.location.href = this.$spgPaymentPortalUrl + response.data.session_token;
                    } else {
                        this.loading = false;
                        Vue.$toast.open({
                            message: "Failed to load online payment gateway! Please try again.",
                            type: response.data.status
                        });
                        console.log(response.data.message);
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });
            }else{
                Vue.$toast.open({message: 'Please select number of years', type: 'error'});
            }
        },
        preparePayment: function(){
            this.renewalYears = this.formData.PaymentYears;
            if(this.formData.StatusId === this.$globalEnums.ApplicationStatuses.New && this.formData.ApplicationType !== this.$globalEnums.ApplicationTypes.Registration){
                this.formActions.payslipExporterNo = this.formData.User.Username;
                this.formActions.payslipTemporaryExporter = false;
            }else{
                this.formActions.payslipTemporaryExporter = true;
            }
        },

        prepareStatusChange: async function (nextStatusId) {
            this.formActions.generateAutomaticInspection = nextStatusId === this.$globalEnums.ApplicationStatuses.Approved && this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Renewal && this.formData.EAInspections.length === 0;

            if (nextStatusId === this.$globalEnums.ApplicationStatuses.Inspection || nextStatusId === this.$globalEnums.ApplicationStatuses.Team_Approval) {
                /*if(this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.Director && this.formData.ApplicationType === this.$globalEnums.ApplicationTypes.Registration){
                    nextStatusId = this.$globalEnums.ApplicationStatuses.Team_Approval;
                }*/
                this.formActions.searchInspectionOfficerKeyword = null;
                this.formActions.availableInspectors = [];
                this.formActions.filteredInspectors = [];
                this.formActions.selectedInspectors = [];
                await this.getInspectionUsers();
                /*if(this.formData.StatusId !== this.$globalEnums.ApplicationStatuses.Team_Approval){
                    this.formActions.selectedInspectors = [];
                    this.formActions.selectedTeamLeader = null;
                }*/
                this.formActions.viewInspectionModal = true;
                this.formActions.inspectionModalTitle = nextStatusId === this.$globalEnums.ApplicationStatuses.Inspection ? 'Send Inspection Team' : 'Inspection Team Approval';
            } else {
                this.formActions.statusChangeComment = null;
                this.formActions.viewStatusChangeModal = true;
                this.formActions.status_change_msg_acdn_open = false;
                if (this.formActions.generateAutomaticInspection) {
                    this.formActions.statusChangeBinNo = this.formData.User.BINNo;
                    this.formActions.statusChangeTinNo = this.formData.User.TINNo;
                }
            }
            this.formActions.nextStatusId = nextStatusId;
        },
        getUsernamePrefix: function(){
            return this.formData.User.ExporterType === this.$globalEnums.ExporterTypes.Textile? 'BD': 'NT';
        },
        changeStatus: async function () {
            this.loading = true;
            await this.$axios.post(this.$globalSettings.api.endpoints.ea.bida.saveBIDAEAStatusLog, {
                EAId: this.formData.Id,
                UserId: this.formData.User.Id,
                Username: this.formActions.statusChangeUsername? this.getUsernamePrefix() + this.formActions.statusChangeUsername: null,
                ServiceOfficeId: this.formData.ServiceOfficeId,
                ValidFrom: this.formData.ValidFrom,
                ValidTill: this.formData.ValidTill,
                BinNo: this.formActions.statusChangeBinNo,
                TinNo: this.formActions.statusChangeTinNo,
                FactoryTypeIds: this.formActions.statusChangeFactoryTypes,
                GenerateAutomaticInspection: this.formActions.generateAutomaticInspection,
                Comment: this.formActions.statusChangeComment,
                StatusId: this.formActions.nextStatusId,
                SendLetter: this.formActions.nextStatusId === this.$globalEnums.ApplicationStatuses.Approved || this.formActions.nextStatusId === this.$globalEnums.ApplicationStatuses.Rejected,
            }).then(async (response) => {
                this.loading = false;
                Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                if (response.data.Status === 'success') {
                    this.formActions.viewStatusChangeModal = false;
                    await this.loadSavedData();
                    if(this.formActions.nextStatusId === this.$globalEnums.ApplicationStatuses.Team_Approval || this.formActions.nextStatusId === this.$globalEnums.ApplicationStatuses.Inspection){
                        await this.saveInspectors();
                    }

                }
            }).catch((error) => {
                console.log(error);
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
            });
        },
        searchHSCode: async function(){
            if(this.formActions.hscodeSearchKeyword.length < 4){
                this.formActions.hsCodeSearchResults = [];
                return false;
            }
            this.loading = true;
            this.formActions.hsCodeSearchResults = [];
            await this.$axios.get(this.$globalSettings.api.endpoints.ea.bida.searchHSCode, { params: { keyword: this.formActions.hscodeSearchKeyword} }).then(async (response) => {
                if (response.data.Status === 'success') {
                    this.formActions.hsCodeSearchResults = response.data.Data;
                }else{
                    Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                }
                this.loading = false;
                //console.log(this.$refs.asd.$el);
                //this.$refs.asd.$el.focus();
            }).catch((error) => {
                this.loading = false;
                this.$globalHelpers.processHttpErrorsToast(error);
                //this.$refs.asd.$el.focus();
            });
        },
        prepareSelectedHSCodes: function (){
            this.formActions.selectedHSCodes = [];
            for(let i = 0; i < this.formData.HSCodeMappings.length; i++){
                this.formActions.selectedHSCodes.push(this.formData.HSCodeMappings[i].HSCode);
            }
        },
        prepareAdditionalData: function (){
            if(this.formData.StatusId === this.$globalEnums.ApplicationStatuses.New && this.formData.TINNo === null){
                this.formData.TINNo = this.formData.User.TINNo;
            }
            if(this.formData.StatusId === this.$globalEnums.ApplicationStatuses.New && this.formData.BINNo === null){
                this.formData.BINNo = this.formData.User.BINNo;
            }
        },
        executeTabChanges: async function (){
            if(this.formData.StatusId === this.$globalEnums.ApplicationStatuses.New && this.formActions.formEditEnabled){
                if(!this.isRequiredAttachmentsUploaded && this.formActions.selectedHSCodes.length > 0){
                    for(let i = 0; i < this.attachments.length; i++){
                        if(this.attachments[i].required && !this.isAttachmentSectionComplete(this.attachments[i].id)){
                            this.selectSection(this.attachments[i].id);
                            console.log('current section', this.attachments[i].label);
                            break;
                        }
                    }
                }else if(this.isRequiredAttachmentsUploaded && this.formActions.selectedHSCodes.length > 0){
                    console.log('current section', 'Payment');
                    this.selectSection(102);
                }
                else{
                    console.log('current section', 'Form');
                    this.selectSection(101);
                }
            }
        },
        formCopyOfficeToFactory: function (){
            this.formData.FactoryAddress = this.formData.OfficeAddress;
            this.formData.FactoryEmail = this.formData.OfficeEmail;
            this.formData.FactoryPhone = this.formData.OfficePhone;
            this.formData.FactoryWebsite = this.formData.OfficeWebsite;
            this.formData.FactoryDistrictId = this.formData.OfficeDistrictId;
            this.formData.FactoryThanaId = this.formData.OfficeThanaId;
        },
        addHSCode: async function(hsCode){
            if(this.formActions.selectedHSCodes.some(x => x.Id === hsCode.Id)){
                Vue.$toast.open({message: 'You have already added this HS code', type: 'warning'});
            }else{
                this.formActions.selectedHSCodes.push(hsCode);
            }
        },
        removeHSCode: async function(hsCode){
            this.formActions.selectedHSCodes = this.formActions.selectedHSCodes.filter(x => x.Id !== hsCode.Id);
        },
        prepareFormDataHSCodes: function (){
            this.formData['HSCodeIds'] = [];
            for(let i = 0; i < this.formActions.selectedHSCodes.length; i++){
                this.formData.HSCodeIds.push(this.formActions.selectedHSCodes[i].Id);
            }
        },

        pdfApplication: async function () {
            console.log('PDF generate command');
            await this.$refs.html2PdfApp.generatePdf();
        },
        pdfPayment: async function () {
            console.log('PDF generate command');
            await this.$refs.html2PdfPay.generatePdf();
        },

        pdfAppStarted: function () {
            console.log('PDF Started');
            this.loading = true;
        },
        pdfAppProgress: function (event) {
            console.log(event);
            console.log('PDF Progress');
            this.loading = event > 99? this.loading = false: this.loading = true;
        },
        pdfAppCompleted: function (event) {
            console.log(event);
            console.log('PDF Completed');
            this.loading = false;
        },
    },
    async created() {
        if (this.$route.params.paymentStatus) {
            this.selectSection(102);
        } else {
            this.selectSection(100);
        }
        this.formActions.exporterTypes = this.$globalHelpers.enumToArray(this.$globalEnums.ExporterTypes).filter(x => x.id <= 2 && x.id !== this.$globalEnums.ExporterTypes.None );
        await this.loadSavedData();
    }
}
</script>

<style scoped>
.app-name{
    color: #794986;
    font-size: 20px;
}
.background{
    background: rgb(101,74,134);
    background: linear-gradient(228deg, rgba(101,74,134,1) 0%, rgba(84,66,145,1) 100%);
}
.login-left-bg{

}
.login-right-bg{
    background: #512DA8;
}
.file-input{
    max-width: 375px;
}
</style>