var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-main',{staticClass:"public-page-bg"},[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"sm":"4","offset-xl":"4","offset-lg":"4","offset-md":"4"}},[_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"mr-3",attrs:{"height":"80","src":"/images/logo.png"}}),_c('img',{attrs:{"height":"80","src":"/images/epb-round.png"}}),_c('div',{staticClass:"font-bold font-size-20 pt-2"},[_vm._v(" "+_vm._s(_vm.$globalSettings.app_name_full)+" ")])]),_c('v-card',{staticClass:"mx-auto my-12 pa-5",attrs:{"loading":_vm.loading}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":_vm.$globalSettings.progressbarClasses,"height":"10","indeterminate":""}})],1),_c('div',{},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-question-circle")]),_vm._v(" Forgot Password")],1),_c('v-divider',{staticClass:"mt-2"}),(_vm.step === 1)?_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendOTP.apply(null, arguments)}}},[_c('div',[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","error-messages":errors,"label":"Account Email","disabled":_vm.loading,"autofocus":"","required":""},model:{value:(_vm.formData.Email),callback:function ($$v) {_vm.$set(_vm.formData, "Email", $$v)},expression:"formData.Email"}})]}}],null,true)})],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"sm":"6"}},[_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","type":"button","plain":""},on:{"click":function($event){return _vm.login()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-angle-left")]),_vm._v(" Login")],1)],1)],1),_c('v-col',{attrs:{"sm":"6"}},[_c('v-card-actions',{staticClass:"d-flex flex-row-reverse"},[_c('v-btn',{attrs:{"type":"submit","disabled":invalid || _vm.loading}},[_vm._v("Continue")])],1)],1)],1)],1)]}}],null,false,4143432943)}):_vm._e(),(_vm.step === 2)?_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.verify.apply(null, arguments)}}},[_c('div',[_c('v-alert',{staticClass:"mt-5",attrs:{"dense":"","outlined":"","type":"info"}},[_vm._v("OTP expires in "+_vm._s(_vm.timerDisplay))]),_c('validation-provider',{attrs:{"name":"OTP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"OTP","disabled":_vm.loading,"required":""},model:{value:(_vm.formData.OTP),callback:function ($$v) {_vm.$set(_vm.formData, "OTP", $$v)},expression:"formData.OTP"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Password","rules":"required|min:6","vid":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","error-messages":errors,"label":"New Password","disabled":_vm.loading,"required":""},model:{value:(_vm.formData.Password),callback:function ($$v) {_vm.$set(_vm.formData, "Password", $$v)},expression:"formData.Password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"PasswordConfirmed","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","error-messages":errors,"label":"Repeat New Password","disabled":_vm.loading,"required":""},model:{value:(_vm.formData.PasswordConfirmed),callback:function ($$v) {_vm.$set(_vm.formData, "PasswordConfirmed", $$v)},expression:"formData.PasswordConfirmed"}})]}}],null,true)})],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"sm":"6"}}),_c('v-col',{attrs:{"sm":"6"}},[_c('v-card-actions',{staticClass:"d-flex flex-row-reverse"},[_c('v-btn',{attrs:{"type":"submit","disabled":invalid || _vm.loading}},[_vm._v("Continue")])],1)],1)],1)],1)]}}],null,false,1336065004)}):_vm._e()],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }